<script setup>

  import { usePageStore } from '@/store/page';
  const pageStore = usePageStore();

</script>

<template>
  <div
    class="md:flex lg:flex xl:flex py-3 bg-right bg-no-repeat titlebar"
    :style="{ backgroundImage: `url(${pageStore.bgimgurl})` }"
    style="background-position-y: 50%; background-size: 60vw; min-height: 92px;"
  >
    <div
      class="sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto md:container lg:container xl:container"
    >
      <h2
        class="mx-5 mt-3 mb-1 xs:mb-4 sm:mb-3 text-3xl xs:text-center sm:text-center"
      >
        {{ pageStore.title }}
      </h2>
      <slot></slot>
    </div>
  </div>
</template>
