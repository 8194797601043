<script setup>

  /**
   * Komponens: morzsamenu
   * 
  */
 
  import { usePageStore } from '@/store/page';
  const pageStore = usePageStore()
  const crumbs = computed(() => pageStore.crumbs)
  const route = useRoute();

  // nyelvi es routing beallitasok
  const localePath = useLocalePath();

  // slug generálás: a mobil nézethez (nem nyúl bele a sima menübe)
  let mobileCategoryPageSlug = ref('');
  watch(() => route.path, async (newVal, oldVal) => {
    if(newVal !== oldVal) {
      console.log('newVal', newVal);
      console.log('oldVal', oldVal);
      // TODO: minden nyelven végigiterálni:
      // alábbi a nyelvi beállításokat és a kezdő / jelet szedi le
      let pathwithoutlang = newVal.replace('/hu/', '');
      if (pathwithoutlang.startsWith('/')) {
        pathwithoutlang = pathwithoutlang.slice(1);
      }
      console.log('slug before: ', pathwithoutlang.split('/')[0]);
      let slug = pathwithoutlang.split('/')[0];
      // Ez egy SOS javítás volt (béna hack) a beégetett főoldali értékek
      //  vizsgálatával az mobilos url-t javítja ha a termék részletezése oldalon vagyunk..
      if(slug === 'konyv') {
        slug = 'konyvek';
      }
      if(slug === 'book') {
        slug = 'books';
      }
      if(slug === 'egyeb-idegen-nyelvu-konyv') {
        slug = 'egyeb-idegen-nyelvu-konyvek';
      }
      if(slug === 'other-language') {
        slug = 'other-languages';
      }
      if(slug === 'notesz-es-ajandektargy') {
        slug = 'noteszek-es-ajandektargyak';
      }
      if(slug === 'stationary-and-gift') {
        slug = 'stationary-and-gifts';
      }      

      mobileCategoryPageSlug.value = localePath({ 
        name: 'menu-navigation-slug', 
        params: { slug },
      });
    }
  });

</script>

<template>
  <nav class="mb-5 mt-2" aria-label="Breadcrumb">
    <!-- Teszteléshez: <p style="max-width: 300px; font-size: 10px; font-family: Verdana, Geneva, Tahoma, sans-serif;">{{ crumbs }}</p> -->
    <ol
      class="flex justify-start xs:justify-center sm:justify-center xs:leading-relaxed sm:leading-relaxed flex-wrap px-4 xs:px-2 sm:px-2 font-sans font-light text-xs xs:p-2 sm:p-2"
      vocab="http://schema.org/"
      typeof="BreadcrumbList"
    >
      <!-- index page (mindig az 1. elem és mindig megjelenik) -->
      <li class="items-center" property="itemListElement" typeof="ListItem">
        <NuxtLink :to="localePath('/')" class="hover:underline">
          <font-awesome-icon :icon="['fa', 'home']" class="pr-1" /><span
            class="xs:hidden sm:hidden"
          >
            {{ $t('Home') }}</span
          >
        </NuxtLink>
        <meta property="position" content="1" />
      </li>
      <!-- other sub pages iteration -->
      <template v-for="(crumb, key) in crumbs" v-show="crumbs.length > 0">
        <li class="mx-1">/</li>
        <!-- az utolso elem: ezen all eppen. xs es sm meretekben nem latszik, 
          jellemzően a kategória levélelem vagy a termék részletezésnél a termék neve nincs link sem, csak a név kell -->
        <li v-if="crumbs.length - key === 1">
          <span>{{ crumb.title }}</span>
        </li>
        <!-- ha nem az utolso elem: kell a link ra: ami mobilon az adott kategoriak menure mutat, egyebkent az adott oldalra -->
        <li v-else class="">
          <!-- mobil nézet eseten: mobilos kategoria lista aloldalt linkeljük be. -->
          <NuxtLink :to="mobileCategoryPageSlug" class="hover:underline lg:hidden xl:hidden">
            {{ crumb.title }}
          </NuxtLink>
          <!-- desktop esetén: az adott url szerinti aloldal linkje-->
          <NuxtLink :to="localePath(crumb.to)" class="hover:underline xs:hidden sm:hidden md:hidden">
            {{ crumb.title }}
          </NuxtLink>
        </li>
      </template>
    </ol>
  </nav>
</template>
